import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Box } from "@xstyled/styled-components"
import Button from "@atoms/Button"
import Container from "@atoms/Container"
import S from "./style"
import MenuItems from "./MenuItems"
import MenuModal from "./MenuModal"
import Context from "./context/context"
import Provider from "./context/provider"

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderContent {
      header: allMarkdownRemark(
        filter: { frontmatter: { slug: { eq: "header" } } }
      ) {
        nodes {
          id
          frontmatter {
            title
            blocks {
              label
              buttonTo
              buttonMode
              items {
                label
                buttonTo
                buttonMode
              }
            }
          }
        }
      }
    }
  `)
  const { blocks: menuItems } = data.header.nodes[0].frontmatter
  return (
    <Provider>
      <Context.Consumer>
        {(context) => (
          <>
            <S.Header bg={{ _: "neutral.grey.100", lg: "transparent" }}>
              <Container px={{ _: "20px", md: "0" }}>
                <S.Navbar
                  row
                  justifyContent={{ md: "space-between" }}
                  alignItems="center"
                >
                  <Box
                    alignItems="center"
                    w="100%"
                    row
                    display={{ _: "flex", lg: "none" }}
                    zIndex={1050}
                  >
                    <Box col={{ _: 1 / 3 }}>
                      <S.HamburgerIcon onClick={context.updateMenuStatus}>
                        <S.HamburgerBox>
                          <S.HamburgerInner active={context.menuOpen} />
                        </S.HamburgerBox>
                      </S.HamburgerIcon>
                    </Box>
                    <Box
                      col={{ _: 1 / 3 }}
                      justifyContent="center"
                      alignItems="center"
                      display={{ _: "flex", lg: "none" }}
                    >
                      <S.Link to="/" onClick={context.closeMenu}>
                        <S.Logo />
                      </S.Link>
                    </Box>

                    <Box
                      col={{ _: 1 / 3 }}
                      display={{
                        _: "inline-flex",
                        md: "inline-flex",
                        lg: "none",
                      }}
                      justifyContent="flex-end"
                    >
                      <S.ButtonHeader
                        buttonMode="link"
                        id="hire-now"
                        className="drift-open-chat"
                      >
                        Hire!
                      </S.ButtonHeader>
                    </Box>
                  </Box>

                  <Box alignItems="center" display={{ _: "none", lg: "flex" }}>
                    <Link to="/">
                      <S.Logo />
                    </Link>
                  </Box>

                  <Box
                    row
                    forwardedAs="nav"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <MenuItems
                      items={menuItems}
                      display={{ _: "none", lg: "flex" }}
                    />

                    <Box ml={30} display={{ _: "none", lg: "flex" }}>
                      <Button
                        id="hire-now"
                        buttonMode="link"
                        className="drift-open-chat"
                      >
                        Hire now!
                      </Button>
                    </Box>

                    {context.menuOpen && (
                      <MenuModal items={menuItems} context={context} />
                    )}
                  </Box>
                </S.Navbar>
              </Container>
            </S.Header>
          </>
        )}
      </Context.Consumer>
    </Provider>
  )
}

export default Header
