import React from "react"
import { isEmpty } from "lodash"
import { Box } from "@xstyled/styled-components"
import S from "./style"
import DropdownContent from "./DropdownContent"

export default ({ items, ...props }) => (
  <S.WrapperMenu {...props}>
    {items.map(({ label, buttonTo, buttonMode, items: children }) => (
      <Box key={buttonTo}>
        {!isEmpty(children) && children.length > 0 ? (
          <S.DropDownLi>
            <S.Dropbtn row alignItems="center" key={label}>
              {label}
              {children.length > 0 && <S.DownArrowFilled ml={5} />}
            </S.Dropbtn>

            <DropdownContent items={children} />
          </S.DropDownLi>
        ) : (
          <S.MenuItem buttonMode={buttonMode} to={buttonTo} key={buttonTo}>
            {label}
          </S.MenuItem>
        )}
      </Box>
    ))}
  </S.WrapperMenu>
)
