import React, { useState } from "react"
import { isEmpty } from "lodash"
import { Box } from "@xstyled/styled-components"
import Button from "@atoms/Button"
import Social from "@molecules/Social"
import SocialIcons from "@utils/socialIcons"
import S from "./style"
import SubMenuModal from "./SubMenuModal"

export default ({ items, context }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false)
  const { closeMenu } = context
  return (
    <S.MenuWrapper backgroundColor="laserYellow">
      <Box
        overflow="auto"
        h="100%"
        maxWidth={{
          sm: "maxWidth.sm",
          md: "maxWidth.md",
        }}
      >
        <S.MenuModalWrapper pt={75}>
          {items.map(({ label, buttonTo, buttonMode, items: children }) => (
            <Box key={buttonTo}>
              {!isEmpty(children) && children.length > 0 ? (
                <>
                  <Box
                    justifyContent="space-between"
                    alignItems="baseline"
                    cursor="pointer"
                    row
                    onClick={() => setSubMenuOpen(!subMenuOpen)}
                  >
                    <S.MenuModalItem
                      buttonMode={buttonMode}
                      to={buttonTo}
                      key={buttonTo}
                    >
                      {label}
                    </S.MenuModalItem>
                    <S.DownArrow />
                  </Box>
                  <SubMenuModal
                    items={children}
                    context={context}
                    open={subMenuOpen}
                  />
                </>
              ) : (
                <Box row>
                  <S.MenuModalItem
                    onClick={context.updateMenuStatus}
                    buttonMode={buttonMode}
                    to={buttonTo}
                    key={buttonTo}
                  >
                    {label}
                  </S.MenuModalItem>
                </Box>
              )}
            </Box>
          ))}
        </S.MenuModalWrapper>
      </Box>
      <Box alignItems="center" className="drift-open-chat" onClick={closeMenu}>
        <Button id="hire-now-sm" fullWidthButton buttonMode="link">
          Hire now!
        </Button>
      </Box>
    </S.MenuWrapper>
  )
}
