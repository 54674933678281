import React from "react"
import styled, { css } from "@xstyled/styled-components"
import { breakpoints, th } from "@xstyled/system"
import { transparentize } from "polished"

const Toast = ({ visible, ...props }) => (
  <S.Toast h="56px" {...props} visible={visible} />
)

const S = {}

S.Toast = styled.div`
  ${breakpoints({
    _: css`
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 10;
      z-index: ${th("zIndices.over")};
      background: ${transparentize(0.3, `black`)};
      color: white;
      width: 100%;
      transition: 1s;
      ${({ visible }) => css`
        /* visibility: ${visible ? "visible" : "hidden"}; */
        opacity: ${visible ? "1" : "0"};
      `}
    `,
    lg: css`
      bottom: 10;
      left: unset;
      right: 10;
      max-width: 375;
      border-radius: 3;
      display: flex;
      align-items: center;
    `,
  })}
`

export default Toast
