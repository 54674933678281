import React, { useEffect, useState } from "react"
import styled, { css } from "@xstyled/styled-components"
import { breakpoints, th } from "@xstyled/system"

import { Link } from "gatsby"
import { Text } from "@atoms/Typography"
import Toast from "@atoms/Toast"

const Cookies = () => {
  // keep for SSR gatsby
  if (typeof window === "undefined" || typeof localStorage === "undefined") {
    return null
  }
  const [scrolled, setScrolled] = useState(false)
  const [cookiesAccepted, setCookiesAccepted] = useState(
    !!window?.localStorage.getItem("cookies-accepted")
  )

  useEffect(() => {
    window?.addEventListener("scroll", handleScroll)
    return () => {
      window?.removeEventListener("scroll", handleScroll)
    }
  })

  const handleScroll = () => {
    let lastScrollTop = 0
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    if (scrollTop > lastScrollTop && !scrolled) {
      setScrolled(true)

      localStorage.setItem("cookies-accepted", true)
      setCookiesAccepted(true)

      window.removeEventListener("scroll", handleScroll)
    }
    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
  }

  return (
    <Toast visible={!scrolled && !cookiesAccepted}>
      <S.Content>
        This website uses <Link to="/privacy-policy">cookies</Link>. By
        continuing to use our site you accept the use of these cookies.
      </S.Content>
    </Toast>
  )
}

const S = {}

S.Content = styled(Text)`
  color: white;
  ${breakpoints({
    _: css`
      > span {
        color: ${th("colors.brand.imabi.orange")};
        font-weight: ${th.fontWeight("medium")};
      }
      font-size: 13;
      line-height: 1.4;
      font-weight: ${th.fontWeight("normal")};
    `,
  })}
  a {
    color: white;
  }
`

export default Cookies
