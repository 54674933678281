import React from "react"
import { x } from "@xstyled/styled-components"
import Container from "@atoms/Container"
import { Text } from "@atoms/Typography"
import S from "./style"

const Footer = () => {
  return (
    <S.Footer>
      <Container>
        <x.div row py={{ _: 80, lg: 84, xl: 114 }}>
          <x.div col={{ _: 1, lg: 1 / 2 }}>
            <S.Title as="h1" pb="29px">
              SQUAD
            </S.Title>
            <S.MenuBox py={30}>
              <Text color="white">&copy; SQUAD 2021, All right reserved</Text>
            </S.MenuBox>
          </x.div>

          <x.div col={{ _: 1, lg: 1 / 2 }}>
            <S.MenuBox py={21}>
              <S.Link to="#how-it-works">How It Works</S.Link>
            </S.MenuBox>
            <S.MenuBox py={21}>
              <S.Link to="#faqs">FAQs</S.Link>
            </S.MenuBox>
            <S.MenuBox py={21}>
              <S.LinkExternal
                target="_blank"
                to="https://squad.global/go/talent-apply"
              >
                For Talent
              </S.LinkExternal>
            </S.MenuBox>
            <S.MenuBox py={21}>
              <S.Link to="/privacy-policy">Terms of Use</S.Link>
            </S.MenuBox>
            <S.MenuBox py={21}>
              <S.Link to="/privacy-policy">Privacy Policy</S.Link>
            </S.MenuBox>
          </x.div>
        </x.div>
      </Container>
    </S.Footer>
  )
}

export default Footer
