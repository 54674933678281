import React from "react"
import S from "./style"

export default ({ items, open, context }) => {
  return (
    <S.SubMenuWrapper open={open}>
      <S.MenuModalWrapper>
        {items.map(({ label, buttonTo, buttonMode }) => (
          <S.SubMenuModalItem
            onClick={context.updateMenuStatus}
            buttonMode={buttonMode}
            mb={10}
            to={buttonTo}
            key={buttonTo}
          >
            {label}
          </S.SubMenuModalItem>
        ))}
      </S.MenuModalWrapper>
    </S.SubMenuWrapper>
  )
}
