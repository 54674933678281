import React from "react"
import { Link } from "gatsby"
import styled, { th, margin, space, css } from "@xstyled/styled-components"
import { ifProp } from "styled-tools"
import variant from "@utils/variant"

const buttonStyle = css`
  height: 60px;
  width: ${ifProp("fullWidthButton", "100%", "auto")};
  padding: 0 38;
  font-size: ${th.fontSize("h5.xs")};
  font-weight: ${th("fontWeights.bold")};
  border-radius: 100px;
  display: inline-flex;
  border: 1px solid ${th("colors.black")};
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.4;
  transition: all 0.3s ease;
  ${margin}
  ${space}

  &:disabled {
    cursor: not-allowed;
    background-color: ${th("colors.black")};
    box-shadow: transparent 0px 0px 0px;
    border-color: ${th("colors.black")};
    color: ${th("colors.white")};

    &:hover {
      border-color: ${th("colors.white")};
      color: ${th("colors.black")};
    }
  }

  ${variant({
    default: "primary",
    variants: {
      primary: css`
        background-color: ${th("colors.black")};
        color: ${th("colors.white")};
        &:hover {
          background: ${th("colors.white")};
          border-color: ${th("colors.white")};
          color: ${th("colors.black")};
        }
      `,
      invert: css`
        background-color: ${th("colors.white")};
        border-color: ${th("colors.white")};
        color: ${th("colors.black")};
        &:hover {
          border-color: ${th("colors.black")};
          background-color: ${th("colors.black")};
          color: ${th("colors.grey")};
        }
      `,
    },
  })}
`

const Button = ({ buttonMode, to, ...props }) => {
  switch (buttonMode) {
    case "link":
      return <ButtonLinkStyled to={to} {...props} />
    case "external":
      return <ButtonLinkExternalStyled href={to} target="_blank" {...props} />
    default:
      return <ButtonStyled {...props} />
  }
}

const ButtonStyled = styled.buttonBox`
  ${buttonStyle}
`

const ButtonLinkStyled = styled(Link)`
  text-decoration: none;
  ${buttonStyle}
`

const ButtonLinkExternalStyled = styled.aBox`
  text-decoration: none;
  ${buttonStyle}
`

export default Button
