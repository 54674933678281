import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

import theme from "@utils/theme"

export const GlobalStyle = createGlobalStyle`
  ${reset}
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    font-variant-ligatures: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${theme.fonts[0]};
    font-size: 16px;
    color: #1a202c;
    overflow-x: hidden;
  }
  body {
    overflow: hidden;
    width: 100%;
    line-height: 1.5;
    letter-spacing: 0;
    background-color: #f7fafc;
  }
  button{
    font-variant-ligatures: none;
  }
`
