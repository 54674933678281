import React from "react"
import { x } from "@xstyled/styled-components"

const Container = (props) => (
  <x.div
    mx="auto"
    px={{ _: "s30", lg: 0 }}
    position="relative"
    maxWidth={{
      sm: "maxWidth.sm",
      md: "maxWidth.md",
      lg: "maxWidth.lg",
      xl: "maxWidth.xl",
    }}
    {...props}
  />
)

export default Container
