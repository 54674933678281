import React from "react"
import { Helmet } from "react-helmet"
import config from "@data/SiteConfig"
import Cookies from "@organisms/Cookies"
import Footer from "@organisms/Footer"
import Header from "@organisms/Header"
import Layout from "./src/layout"

export const wrapPageElement = ({ element }) => (
  <>
    <Helmet>
      <meta name="description" content={config.siteDescription} />
      <html lang="en" />
      <meta name="image" content={config.siteSocial} />
      <meta property="og:image" content={config.siteSocial} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={config.siteSocial} />
    </Helmet>
    <Layout>
      <Header />
      <main>{element}</main>
      <Footer />
      <Cookies />
    </Layout>
  </>
)
