import React from "react"
import styled, { x } from "@xstyled/styled-components"
import idgen from "@utils/idgen"
import { StaticQuery, graphql } from "gatsby"
import LinkedinIcon from "@assets/svg/linkedin.svg"
import TwitterIcon from "@assets/svg/twitter.svg"
import InstagramIcon from "@assets/svg/instagram.svg"
import FacebookIcon from "@assets/svg/facebook.svg"

const Social = ({...props }) => {
  return (
    <StaticQuery
      query={graphql`
        query SocialContent {
          index: allMarkdownRemark(
            filter: { frontmatter: { slug: { eq: "social" } } }
          ) {
            nodes {
              id
              frontmatter {
                blocks {
                  items {
                    socialType
                    enable
                    url
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          {data.index.nodes[0].frontmatter.blocks[0].items && (
            <x.div
              display="flex"
              justifyContent="center"
              py={{ _: 20, md: 47 }}
            >
              {data.index.nodes[0].frontmatter.blocks[0].items.map((item) => (
                <React.Fragment key={idgen()}>
                  {item.socialType === "linkedin" && item.enable && (
                    <S.SocialItem href={item.url} {...props}>
                      <S.LinkedinIcon />
                    </S.SocialItem>
                  )}
                  {item.socialType === "twitter" && item.enable && (
                    <S.SocialItem href={item.url} {...props}>
                      <S.TwitterIcon />
                    </S.SocialItem>
                  )}
                  {item.socialType === "facebook" && item.enable && (
                    <S.SocialItem href={item.url} {...props}>
                      <S.FacebookIcon />
                    </S.SocialItem>
                  )}
                  {item.socialType === "instagram" && item.enable && (
                    <S.SocialItem href={item.url} {...props}>
                      <S.InstagramIcon />
                    </S.SocialItem>
                  )}
                </React.Fragment>
              ))}
            </x.div>
          )}
        </>
      )}
    />
  )
}

const S = {}

S.SocialItem = styled.aBox`
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  margin-left: 31;
  width: 22;
  height: 22;
`
S.LinkedinIcon = styled.box`
  background-image: url(${LinkedinIcon});
  width: 22;
  height: 22;
  cursor: pointer;
  background-size: cover;
`
S.FacebookIcon = styled.box`
  background-image: url(${FacebookIcon});
  cursor: pointer;
  width: 22;
  height: 22;
  background-size: cover;
`
S.InstagramIcon = styled.box`
  background-image: url(${InstagramIcon});
  width: 22;
  height: 22;
  cursor: pointer;
  background-size: cover;
`
S.TwitterIcon = styled.box`
  background-image: url(${TwitterIcon});
  width: 22;
  height: 22;
  cursor: pointer;
  background-size: cover;
`
export default Social
