import React from "react"
import { ThemeProvider, Preflight } from "@xstyled/styled-components"
import { Helmet } from "react-helmet"
import { GlobalStyle } from "@assets/global-style"
import { withPrefix } from "gatsby"

import theme from "@utils/theme"

import "./stylesheet.css"

export default function MainLayout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Helmet title="SQUAD">
        <script src={withPrefix("drift.js")} type="text/javascript" />
      </Helmet>

      <Preflight />
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}
