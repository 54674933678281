import React from "react"
import styled, { css, breakpoints } from "@xstyled/styled-components"
import { th } from "@xstyled/system"
import { Text } from "@atoms/Typography"
import { Link } from "gatsby"
import Button from "@atoms/Button"
import logoBlack from "@assets/svg/logo_black.svg"
import downArrow from "@assets/svg/down-arrow.svg"
import downArrowFilled from "@assets/svg/down-arrow-filled.svg"

const S = {}

S.Header = styled.headerBox`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1050;
`

S.Link = styled(Link)`
  display: inline-block;
`
S.Logo = styled.box`
  background-image: url(${logoBlack});
  background-repeat: no-repeat;
  width: 158;
  height: 27;
  cursor: pointer;
  background-size: contain;
  background-position: center;
  ${breakpoints({
    _: css`
      width: 112px;
      height: 18px;
    `,
    lg: css`
      width: 158;
      height: 27;
    `,
  })}
`

S.DownArrowFilled = styled.box`
  background-image: url(${downArrowFilled});
  width: 15px;
  height: 15px;
  cursor: pointer;
  background-size: cover;
`

S.DownArrow = styled.box`
  background-image: url(${downArrow});
  width: 28px;
  height: 28px;
  cursor: pointer;
  background-size: cover;
`

S.Navbar = styled.box`
  ${breakpoints({
    _: css`
      height: 70px;
    `,
    lg: css`
      height: 101px;
    `,
  })}
`

S.HamburgerBox = styled.box`
  position: relative;
  width: 28px;
  height: 28px;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
`

S.HamburgerInner = styled.box`
  top: 50%;
  display: block;
  margin-top: -2px;
  position: absolute;
  width: 23px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: ${th("colors.black")};
  &:before,
  &:after {
    position: absolute;
    width: 23px;
    height: 3px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: ${th("colors.black")};
    display: block;
    content: "";
  }
  &:before {
    top: -8px;
  }
  &:after {
    bottom: -8px;
  }
  ${(props) =>
    props.active &&
    css`
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(45deg);
      &:after {
        bottom: 0;
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: rotate(-90deg);
      }
      &:before {
        top: 0;
        transition: top 75ms ease, opacity 75ms ease 0.12s;
        opacity: 0;
      }
    `}
  ${(props) =>
    !props.active &&
    css`
      &:before {
        transition: top 75ms ease, opacity 75ms ease 0.12s;
      }
      &:after {
        transition: bottom 75ms ease 0.12s,
          transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    `}
`

S.HamburgerIcon = styled.box`
  font: inherit;

  overflow: visible;
  margin: 0;

  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  width: 28px;
  height: 28px;
`

S.WrapperMenu = styled.box`
  display: flex;
  align-items: center;
  justify-self: flex-end;
`

S.MenuItem = styled((props) => <Button {...props} />)`
  font-size: ${th.fontSize("pl.lg")};
  padding: 1 15;
  line-height: 1.3;
  text-decoration: none;
  font-family: ${th.font("1")};
  font-weight: ${th("fontWeights.normal")};
  border: 0;
  background-color: transparent;
  color: ${th("colors.black")};
  transition: color 0.3s ease;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
`

S.ButtonHeader = styled((props) => <Button {...props} />)`
  height: auto;
  padding: 5px 10px;
`

S.ButtonMenuMobile = styled.buttonBox`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20;
  height: 20;
  border-radius: 50%;
  border: none;
  padding: 0;
  transition: all 0.3s ease;
  cursor: pointer;
`

S.MenuWrapper = styled.box`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  z-index: ${th("zIndices.menu")};
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  padding: 30 36;
  width: 100%;
  ${({ open }) =>
    open &&
    css`
      transform: translateY(0%);
      opacity: 1;
    `};
  ${breakpoints({
    _: css`
      font-size: 18;
    `,
    sm: css`
      padding-right: 15%;
      padding-left: 15%;
    `,
    lg: css`
      transform: translateY(100%);
      opacity: 0;
    `,
  })}
`

S.SubMenuWrapper = styled.box`
  display: ${({ open }) => (open ? "flex" : "none")};
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  z-index: ${th("zIndices.over")};
  transition: all 0.9s ease;
  padding: 0;
  width: 100%;
`

S.MenuModalWrapper = styled.box`
  display: flex;
  flex-direction: column;
`

S.MenuModalItem = styled((props) => <Button {...props} />)`
  font-weight: ${th("fontWeights.bold")};
  font-size: ${th.fontSize("h3.xl")};
  text-decoration: none;
  border: 0;
  background-color: laserYellow;
  padding: 0;
  margin-bottom: 16;
  color: ${th("colors.black")};
  &:hover {
    background-color: laserYellow;
    text-decoration: underline;
    color: ${th("colors.black")};
  }
`

S.SubMenuModalItem = styled((props) => <Button {...props} />)`
  font-size: ${th.fontSize("h4.xs")};
  text-decoration: none;
  font-weight: ${th("fontWeights.normal")};
  border: 0;
  background-color: white;
  color: ${th("colors.neutral.black")};
  margin-bottom: 10;
  padding: 0;
  width: 100%;
  justify-content: flex-start;
  &:hover {
    text-decoration: underline;
    background-color: white;
    color: ${th("colors.neutral.black")};
  }
`

S.MenuModalText = styled(Text)`
  max-width: 240;
  font-weight: ${th("fontWeights.medium")};
  margin-bottom: 15;

  ${breakpoints({
    _: css`
      font-size: 18;
    `,
    sm: css`
      font-size: 20;
    `,
  })}
`

S.DropDownLi = styled(S.MenuItem)`
  display: inline-block;
  &:hover {
    display: block;
  }
`

S.Dropbtn = styled.box`
  display: inline-block;
  color: ${th("colors.neutral.black")};
  text-align: center;
  padding: 10px 16px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

S.DropDownContent = styled.box`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 215px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: ${th("zIndices.menu")};
  ${S.DropDownLi}:hover & {
    display: block;
  }
  ${S.Dropbtn}:hover & {
    text-decoration: underline;
    display: block;
  }
  &:before {
    border-bottom: 14px solid rgba(0, 0, 0, 0.2);
    border-left: 15px solid rgba(0, 0, 0, 0);
    border-right: 15px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    left: 40%;
    position: absolute;
    top: -14px;
  }

  &:after {
    border-bottom: 14px solid #ffffff;
    border-left: 15px solid rgba(0, 0, 0, 0);
    border-right: 15px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    left: 40%;
    position: absolute;
    top: -14px;
  }
`

S.SubA = styled((props) => <Button {...props} />)`
  color: ${th("colors.neutral.black")};
  font-weight: ${th("fontWeights.normal")};
  font-size: 18;
  line-height: 2;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
  background-color: white;
  color: ${th("colors.neutral.black")};
  border: 0;
  &:hover {
    background-color: ${th("colors.neutral.grey.200")};
  }
  ${S.Dropbtn}:hover & {
    text-decoration: underline;
    display: block;
  }
`

export default S
