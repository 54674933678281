import { rpxTransformers } from "@xstyled/system"
import { defaultTheme, th } from "@xstyled/styled-components"

const space = {
  s5: 5,
  s10: 10,
  s15: 15,
  s20: 20,
  s25: 25,
  s30: 30,
  s35: 35,
  s40: 40,
  s45: 45,
  s50: 50,
  s55: 55,
  s60: 60,
  s65: 65,
  s70: 70,
  s75: 75,
  s80: 80,
  s85: 85,
  s90: 90,
  s95: 95,
  s100: 100,
  s105: 105,
  s110: 110,
  s115: 115,
  s120: 120,
  s125: 125,
  s130: 130,
  s135: 135,
  s140: 140,
  s145: 145,
  s150: 150,
  s155: 155,
  s160: 160,
  s165: 165,
  s170: 170,
  s175: 175,
  s180: 180,
  s185: 185,
  s190: 190,
  s195: 195,
  s200: 200,
  s205: 205,
  s210: 210,
  s215: 215,
  s220: 220,
  s225: 225,
  s230: 230,
  s235: 235,
  s240: 240,
  s245: 245,
  s250: 250,
  s255: 255,
  s260: 260,
  s265: 265,
  s270: 270,
  s275: 275,
  s280: 280,
  s285: 285,
  s290: 290,
  s295: 295,
  s300: 300,
  s305: 305,
  s350: 350,
  s355: 355,
  s380: 380,
  s385: 385,
  s390: 390,
  s395: 395,
  s400: 400,
  s410: 410,
  s420: 420,
  s430: 430,
  s440: 440,
  s470: 470,
  s490: 490,
  s520: 520,
  s535: 535,
  s550: 550,
}

const theme = {
  ...defaultTheme,
  transformers: {
    ...rpxTransformers,
  },
  fonts: ["Adieu", "Moderat"],
  screens: {
    _: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1280,
  },
  sizes: {
    ...defaultTheme.sizes,
    ...space,
    maxWidth: {
      _: th.size("full"),
      sm: 540,
      md: 720,
      lg: 890,
      xl: 1140,
    },
  },
  space: {
    ...space,
    xs: {
      padding: 30,
    },
  },
  fontSizes: {
    h1: {
      xs: 36,
      lg: 52,
      xl: 87,
    },
    h2: {
      xs: 30,
      lg: 42,
      xl: 52,
    },
    h3: {
      xs: 24,
      lg: 36,
      xl: 36,
    },
    h4: {
      xs: 20,
      lg: 22,
      xl: 28,
    },
    h5: {
      xs: 18,
      lg: 18,
      xl: 20,
    },
    pxl: {
      xs: 0,
      lg: 0,
      xl: 0,
    },
    pl: {
      xs: 22,
      lg: 20,
      xl: 20,
    },
    p: {
      xs: 18,
      lg: 18,
      xl: 18,
    },
    pxs: {
      xs: 14,
      lg: 14,
      xl: 14,
    },
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  colors: {
    laserYellow: "#FFFF61",
    hotPink: "#F49AE0",
    mountainMeadow: "#19AB6A",
    heliotrope: "#8C70FF",
    black: "#000000",
    white: "#FFFFFF",
    grey: "#EEEBEB",
  },
  zIndices: {
    menu: 8,
    over: 9,
  },
}

export default theme
