import React from "react"
import { Link } from "gatsby"
import styled, { breakpoints, css } from "@xstyled/styled-components"
import { th } from "@xstyled/system"
import Button from "@atoms/Button"
import { Text } from "@atoms/Typography"

const S = {}

S.Footer = styled.footerBox`
  background: ${th("colors.black")};
  color: ${th("colors.white")};
`

S.MenuFooterItem = styled.box`
  font-size: 20;
  margin-bottom: 16;
  border-color: transparent;
`

S.MenuBox = styled.box`
  width: fit-content;
`

S.MainMenuBox = styled.box`
  ${breakpoints({
    lg: css`
      padding-left: 120;
    `,
  })}

  &:first-of-type {
    padding-left: 0;
  }
`

S.MenuItem = styled((props) => <Button {...props} />)`
  font-size: ${th.fontSize("p.xs")};
  padding: 0;
  line-height: 1.3;
  text-decoration: none;
  font-weight: ${th("fontWeights.normal")};
  border: 0;
  background-color: transparent;
  transition: color 0.3s ease;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
    color: white;
  }
`

S.Title = styled(Text)`
  font-size: 27;
  font-weight: ${th("fontWeights.bold")};
  color: white;
  text-align: left;
`

const footerLink = css`
  color: ${th("colors.white")};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${breakpoints({
    _: css`
      font-size: ${th.fontSize("pl.xs")};
    `,
    lg: css`
      font-size: ${th.fontSize("pl.lg")};
    `,
  })}
`

S.Link = styled(Link)`
  ${footerLink}
`

S.LinkExternal = styled.a`
  ${footerLink}
`

export default S
