import React from "react"
import S from "./style"

export default ({ items }) => {
  return (
    <S.DropDownContent>
      {items.map(({ label, buttonTo, buttonMode }) => (
        <S.SubA key={buttonTo} to={buttonTo} buttonMode={buttonMode}>
          {label}
        </S.SubA>
      ))}
    </S.DropDownContent>
  )
}
