import styled, { th, breakpoints, css } from "@xstyled/styled-components"
import { getThemeValue, merge, warn, is, assign } from "@xstyled/util"

const variant =
  ({ key = null, default: defaultValue, variants = {}, prop = "variant" }) =>
  (props) => {
    const themeVariants = is(key) ? getThemeValue(props, key) : null
    const computedVariants = merge(assign({}, variants), themeVariants)
    const value = props[prop] !== undefined ? props[prop] : defaultValue
    const result = getThemeValue(props, value, computedVariants)
    warn(is(result), `variant "${value}" not found`)
    return result
  }

export const H1 = css`
  line-height: 1.1;
  ${breakpoints({
    _: css`
      font-size: ${th.fontSize("h1.xs")};
    `,
    lg: css`
      font-size: ${th.fontSize("h1.lg")};
    `,
    xl: css`
      font-size: ${th.fontSize("h1.xl")};
    `,
  })}
`

export const H2 = css`
  line-height: 1.1;
  ${breakpoints({
    _: css`
      font-size: ${th.fontSize("h2.xs")};
    `,
    lg: css`
      font-size: ${th.fontSize("h2.lg")};
    `,
    xl: css`
      font-size: ${th.fontSize("h2.xl")};
    `,
  })}
`

export const H3 = css`
  line-height: 1.3;
  ${breakpoints({
    _: css`
      font-size: ${th.fontSize("h3.xs")};
    `,
    lg: css`
      font-size: ${th.fontSize("h3.lg")};
    `,
    xl: css`
      font-size: ${th.fontSize("h3.xl")};
    `,
  })}
`

export const H4 = css`
  line-height: 1.3;
  ${breakpoints({
    _: css`
      font-size: ${th.fontSize("h4.xs")};
    `,
    lg: css`
      font-size: ${th.fontSize("h4.lg")};
    `,
    xl: css`
      font-size: ${th.fontSize("h4.xl")};
    `,
  })}
`

export const H5 = css`
  line-height: 1.3;
  ${breakpoints({
    _: css`
      font-size: ${th.fontSize("h5.xs")};
    `,
    lg: css`
      font-size: ${th.fontSize("h5.lg")};
    `,
    xl: css`
      font-size: ${th.fontSize("h5.xl")};
    `,
  })}
`

export const PXL = css`
  ${breakpoints({
    _: css`
      font-size: ${th.fontSize("pxl.xs")};
    `,
    lg: css`
      font-size: ${th.fontSize("pxl.lg")};
    `,
    xl: css`
      font-size: ${th.fontSize("pxl.xl")};
    `,
  })}
`

export const PL = css`
  ${breakpoints({
    _: css`
      font-size: ${th.fontSize("pl.xs")};
    `,
    lg: css`
      font-size: ${th.fontSize("pl.lg")};
    `,
    xl: css`
      font-size: ${th.fontSize("pl.xl")};
    `,
  })}
`

export const P = css`
  font-family: ${th.font("1")};
  ${breakpoints({
    _: css`
      font-size: ${th.fontSize("p.xs")};
    `,
    lg: css`
      font-size: ${th.fontSize("p.lg")};
    `,
    xl: css`
      font-size: ${th.fontSize("p.xl")};
    `,
  })}
`

export const PP = css`
  ${breakpoints({
    _: css`
      font-size: ${th.fontSize("pxs.xs")};
    `,
    lg: css`
      font-size: ${th.fontSize("pxs.lg")};
    `,
    xl: css`
      font-size: ${th.fontSize("pxs.xl")};
    `,
  })}
`

export const Title = styled.divBox`
  font-weight: ${th.fontWeight("bold")};
  color: ${th.color("black")};
  ${variant({
    default: "h1",
    variants: {
      h1: H1,
      h2: H2,
      h3: H3,
      h4: H4,
      h5: H5,
    },
  })}
`

export const textCss = css`
  font-weight: ${th.fontWeight("normal")};
  font-family: ${th.font("1")};
  color: ${th.color("black")};
  line-height: 1.4;
  ${variant({
    default: "xs",
    variants: {
      xs: PP,
      sm: P,
      lg: PL,
      xl: PXL,
    },
  })}
  white-space: pre-line;
`

export const Text = styled.pBox`
  ${textCss}
`
