import React, { useState, useEffect } from "react"
import PackageContext from "./context"

const NavigationProvider = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    function setOverflow() {
      if (!menuOpen) {
        document.body.style.overflow = "hidden"
      } else {
        document.body.style.overflow = "auto"
      }
    }
    return () => {
      setOverflow()
    }
  }, [menuOpen])

  return (
    <PackageContext.Provider
      value={{
        menuOpen,
        updateMenuStatus: () => {
          setMenuOpen(!menuOpen)
        },
        closeMenu: () => {
          setMenuOpen(false)
        },
      }}
    >
      {children}
    </PackageContext.Provider>
  )
}

export default NavigationProvider
