const config = {
  siteTitle: "SQUAD", // Site title.
  siteTitleShort: "SQUAD", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Hire the digital team you need", // Alternative site title for SEO.
  siteLogo: "/android-chrome-512x512.png", // Logo used for SEO and manifest.
  siteSocial: "/social-share.png",
  siteUrl: "https://squad.gloabl", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "Hire the digital team you need", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteRssTitle: "SQUAD", // Title of the RSS feed
  siteFBAppID: "", // FB Application ID for using app insights
  googleTagManagerContainerID: "GTM-PQXKWPM",
  disqusShortname: "", // Disqus shortname.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  postsPerPage: 4, // Amount of posts displayed per listing page.
  userName: "Advanced User", // Username to display in the author segment.
  userEmail: "AdvancedUser@example.com", // Email used for RSS feed's author segment
  userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "North Pole, Earth", // User location to display in the author segment.
  userAvatar: "https://api.adorable.io/avatars/150/test.png", // User avatar to display in the author segment.
  userDescription:
    "Yeah, I like animals better than people sometimes... Especially dogs. Dogs are the best. Every time you come home, they act like they haven't seen you in a year. And the good thing about dogs... is they got different dogs for different people.", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "GitHub",
      url: "https://github.com/Vagr9K/gatsby-advanced-starter",
      iconClassName: "fa fa-github",
    },
    {
      label: "Twitter",
      url: "https://twitter.com/Vagr9K",
      iconClassName: "fa fa-twitter",
    },
    {
      label: "Email",
      url: "mailto:hello@squad.global",
      iconClassName: "fa fa-envelope",
    },
  ],
  copyright: "Copyright © 2021. SQUAD", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#ffffff", // Used for setting manifest and progress theme colors.
  backgroundColor: "#ffffff", // Used for setting manifest background color.
  mailChimpAction:
    process.env.NODE_ENV === "production"
      ? "https://imabi.us1.list-manage.com/subscribe/post?u=fd3d2797092ae533f71e2c9c8&amp;id=88190d06f8"
      : "https://crowdform.us14.list-manage.com/subscribe/post?u=ec2caeb4eef5ec5e36e3867d9&amp;id=87b1f16eda",
}

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = ""
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1)

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`

module.exports = config
